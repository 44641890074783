/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

// Summary And Greeting Section

import emoji from "react-easy-emoji";

const illustration = {
  animated: true // set to false to use static SVG
};

const greeting = {
  username: "Syyam Noor",
  title: "Hello World, It's Syyam",
  subTitle: emoji(
    "A Computer Sceince graduate, passionate about building Mobile applications 📲 with Android Studio / React Native and some other cool libraries and frameworks while having experience in Data science and Machine learning based research 🤖."
  ),
  // resumeLink:
  //   "https://drive.google.com/file/d/1jVkDvKBpskfXlzDW-9mAI1XF-rbggXaJ/view?usp=sharing",
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/syyam",
  linkedin: "https://www.linkedin.com/in/syyamnoor/",
  gmail: "syyamnoor@gmail.com",
  medium: "https://medium.com/@syyamnoor10",
  stackoverflow: "https://stackoverflow.com/users/9980884/abbu-jan",
  // Instagram and Twitter are also supported in the links!
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "FIERY SOFTWARE DEVELOPER WHO WANTS TO WRITE APPS AND ALGOS",
  skills: [
    emoji(
      "🌵 Develop highly interactive Front end / User Interfaces for your mobile applications"
    ),
    emoji("🌵 Extracting insights from raw data, and presenting those insights to others"),
    emoji(
      "🌵 Integration of third party services such as Firebase/ AWS / Digital Ocean and multi disciplinary R&D"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Android",
      fontAwesomeClassname: "fab fa-android"
    },
    {
      skillName: "React Native",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "Java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "Kotlin",
      fontAwesomeClassname: "fab fa-kickstarter"
    },
    {
      skillName: "Tensorflow",
      fontAwesomeClassname: "fas fa-robot"
    },
    {
      skillName: "Keras",
      fontAwesomeClassname: "fab fa-kaggle"
    },
    {
      skillName: "Python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "git",
      fontAwesomeClassname: "fab fa-git"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "COMSATS University",
      logo: require("./assets/images/comsatsLogo.jpg"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "September 2015 - January 2019",
      desc: "Participated in the research of specializing in AI and Computer Vision. I participated in various competitions and hackathons. I am always on the lookout for new technologies and up for a challenge. My eagerness for learning lead me to publish a research paper in the field of computer vision at BS level and accomplishing other specializations in Machine Learning.",
    
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "App development", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Machine Learning",
      progressPercentage: "75%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Android Developer",
      company: "Seamless Distribution Systems",
      companylogo: require("./assets/images/sdsLogo.jpg"),
      date: "Apr 2022 – Present",
      desc: "Working as an android developer at SDS, a Swedish fintech group, where my reponsibility is to provide end-to-end services for digital sales and distribution to private consumers through mobile operators.",
      descBullets: [
        "Collaborated with the team to design the architecture and develop applications for telecommunication companies",
        "Managing credit transactions by resellers and subscribers",
        "Determined and implemented required changes, while maintaining and administering systems.",
        "Enhanced backward compatibility by introducing new features and following the industry standard clean architecture approach"
      ]
    },
    {
      role: "Android Developer",
      company: "Afiniti",
      companylogo: require("./assets/images/afinitiLogo.png"),
      date: "Feb 2021 – Apr 2022",
      desc: "Being an app developer my main responsibilites were to further develop the Younite App. Younite is a way to hold online conferences and parties with your friends and work-mates with not just one but multiple phones all connected together.",
      descBullets: [
        "To produce the prototype solutions and meaningful research for my team",
        "I worked on syncing algorithm to sync multiple servers and clients to establish a solid and smooth connection between devices.",
        "Did extensive research on RTP, SIP and other related VOIP protocols.",
        "Implementing new and improving existing layouts in our Apps",
        "Migrating the whole app architecture from MVC to MVVM using Dagger multi binding."
      ]
    },
    {
      role: "Software Engineer",
      company: "Abacus Consulting",
      companylogo: require("./assets/images/abacusLogo.png"),
      date: "Sep 2019 – Feb 2021",
      desc: "Worked with the team to build a market-leading mobile banking application (uPaisa) for Pakistan's top rated GSM cellular service provider - ufone.",
      descBullets: [
        "Working on UI design and Application Architecture of uPaisa app",
        "Integrating third Party API/Plugins, testing, polishing, publishing, and the App Security of the android application.",
        "Distribution and support for both iOS and Android applications.",
        "Implemented End to End Robotic Process Automation (RPA) using UiPath.",
        "Worked with OCR, ICR, and Machine learning for designing in UiPath studio",
      ]
    },
    {
      role: "Software Engineer",
      company: "WBM International",
      companylogo: require("./assets/images/wbmLogo.png"),
      date: "July 2018 – Nov 2018",
      desc: "Developed a native android application for the company’s own E-commerce app named retail solution.",
      descBullets: [
        "Planned, tested, and implemented various Android-based applications.",
        "Worked on an E-commerce based react native app for the company’s products.",
        "Developed and implemented an android application for the company’s invoice management.",
        "Worked with the Business Analysts in identifying and defining the requirements.",

      ]
    
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projects",
  subtitle: "SOME OF THE BIG PROJECTS I DEVELOPED OVER THE YEARS. 😎👉🏽👉🏽",
  projects: [
    {
      image: require("./assets/images/upaisaLogo.png"),
      projectName: "uPaisa",
      projectDesc: "UPaisa Wallet is a branchless banking Wallet opened on your mobile number. It can be created by any network user to transfer money, mobile load, pay bills and much more.",
      footerLink: [
        {
          name: "Play Store",
          url: "https://play.google.com/store/apps/details?id=pk.upaisa.com&hl=en&gl=US"
        },
        {
          name: "App Store",
          url: "https://apps.apple.com/pk/app/upaisa/id1536734651"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/covidLogo.jpg"),
      projectName: "Covid-19 Detector",
      projectDesc: "The aim of this project was to create a web application for detection of COVID through frontal Chest X-rays using Machine Learning (LENET).",
      footerLink: [
        {
          name: "View Source Code",
          url: "https://github.com/syyam/adeelaISecond.github.io"
        },
        {
          name: "Website",
          url: "https://syyamic-covid-classifier.herokuapp.com/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/meemeeLogo.png"),
      projectName: "MeeMee",
      projectDesc: "Social media platform developed only for the memes.",
      footerLink: [
        {
          name: "Play Store",
          url: "https://play.google.com/store/apps/details?id=com.GPK.syyam.saifapplication&hl=en_AU"
        }
        //  you can add extra buttons here.
      ]
    },
    
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Researh Papers and Some Cool Stuff that I have done!",

  achievementsCards: [
    {
      title: "Real-Time Rehabilitation and Fitness System using Depth Sensor",
      subtitle:
        "This paper presents an action verification system for rehabilitation of patients with limited mobility...",
      image: require("./assets/images/ieeeLogo.png"),
      footerLink: [
        {
          name: "Research Paper",
          url: "https://ieeexplore.ieee.org/document/8991675"
        }
      ]
    },
    {
      title: "AI for Medical Diagnosis",
      subtitle:
        "This program gives you practical experience in applying cutting-edge machine learning techniques to concrete problems in modern medicine:",
      image: require("./assets/images/deepLearnignLogo.png"),
      footerLink: [
        {
          name: "Certification",
          url: "https://www.coursera.org/account/accomplishments/certificate/84KTNPRRP7SF"
        }
      ]
    },
    {
      title: "Neural Networks and Deep Learning",
      subtitle:
        "The foundational concept of neural networks and deep learning.",
      image: require("./assets/images/deepLearnignLogo.png"),
      footerLink: [
        {
          name: "Certification",
          url: "https://www.coursera.org/account/accomplishments/verify/MV6YL7G3YJD9"
        }
      ]
    },

    {
      title: "UIPath Advanced Training",
      subtitle: "UiPath Certified Professional is a high-stakes certification program designed to help you advance your career in robotic process automation (RPA) solutions.",
      image: require("./assets/images/uiPathLogo.png"),
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "Training",
          url: ""
        }
      ]
    },

    {
      title: "Android App Development",
      subtitle: "Got a certificate for Android app development from a reputable institute.",
      image: require("./assets/images/aptechLogo.png"),
      footerLink: [
        {name: "Certificate", url: "https://drive.google.com/file/d/1-UnAfdE4CrlTbjjE-0RqDQppJzvQ37-E/view?usp=sharing"},
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt. I plan on to write more blogs in future.",

  blogs: [
    {
      url: "https://syyamnoor10.medium.com/chest-x-ray-image-analysis-and-classification-for-covid-19-pneumonia-detection-using-deep-cnn-1e1367618346",
      title: "Chest X-ray image analysis and classification for COVID-19 pneumonia detection using Deep CNN",
      description:
        "Chest X-ray image analysis and classification for COVID-19 pneumonia detection using Deep CNN"
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all. 👉🏼👈🏼",
  // number: "+92-(335) 5011998",
  email_address: "syyamnoor@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "syyamNoor10", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
